import React from 'react';
import { Link } from 'react-router-dom';

import { Alert } from 'shared/images/icons';
import styles from './tabs.module.scss';

export type TItem = {
  id: number | string,
  label: string,
  amount?: number | undefined,
  isError?: boolean | undefined,
  url: string,
};

type TItemProps = {
  label: string,
  isSelected: boolean,
  amount?: number | undefined,
  isError?: boolean,
  url: string,
};

function Item({ label, isSelected, amount, isError, url }: TItemProps) {
  const selectedClassName = isSelected ? styles['item--selected'] : '';

  return (
    <div className={styles['item-wrapper']}>
      <div className={`${styles.item} ${selectedClassName}`}>
        <Link to={url}><span>{label}</span></Link>
        {(amount && !isError) && <div className={styles.amount}>{amount}</div>}
        {isError && <Alert className={styles.error} />}
      </div>
      {isSelected && <hr />}
    </div>
  );
}

export type TRenderTabs = {
  items: TItem[],
  selectedItem: number| string | null | undefined,
};

const renderTabs = ({ items, selectedItem }: TRenderTabs) => (
  items.map((item) => (
    <Item
      key={item?.id}
      label={item?.label}
      amount={item?.amount}
      isSelected={item?.id === selectedItem}
      isError={item?.isError}
      url={item?.url}
    />
  ))
);

type TProps = {
  items: TItem[],
  selectedItem: number | string | null | undefined,
};

export function TabsDown({ items, selectedItem }: TProps) {
  return (
    <div className={styles.container}>{renderTabs({ items, selectedItem })}</div>
  );
};

import React from 'react';

export interface IContainerLoader {
  component?: React.ReactElement | null;
  loaderComponent?: React.ReactElement | null;
  noItemsComponent?: React.ReactElement | null;
  errorComponent?: React.ReactElement | null;
  isLoading?: boolean;
  itemsQuantity?: number | null;
  isError?: boolean;
}

export function ContainerLoader({
  isLoading,
  isError,
  loaderComponent = null,
  component = null,
  errorComponent = null,
  itemsQuantity,
  noItemsComponent = null,
}: IContainerLoader) {
  if (isLoading) return loaderComponent;

  if (isError) return errorComponent;

  if (!itemsQuantity) return noItemsComponent;

  return component;
}

import React from 'react';
import { Outlet } from 'react-router-dom';

import { Menu } from './Menu';
import Header from './Header';
import styles from './layout.module.scss';

export function Layout() {
  return (
    <div className={styles.container}>
      <Menu />
      <div className={styles.rightSide}>
        <Header />
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import { useAppSelector } from 'shared/hooks/redux';

import styles from './headerTitle.module.scss';

function HeaderTitle() {
  const title = useAppSelector((state) => state.headerTitle.title);

  return (
    <h2 className={styles.title}>{title}</h2>
  );
};

export { HeaderTitle };

import { Layout } from 'app/Layout';
import CustomerDetailsLayout from 'features/customers/customerDetails/CustomerDetailsLayout';
import SupplierDetailsLayout from 'features/suppliers/SupplierDetailsLayout';
import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'features/ErrorBoundary';
import { NotFoundPage } from 'pages/NotFoundPage';

import routs from 'shared/routs';
import { withAuth } from 'shared/hocs/withAuth';

const SignIn = React.lazy(() => import('pages/SignIn'));
const Bindings = React.lazy(() => import('pages/Bindings'));
const BindingsDetails = React.lazy(() => import('pages/BindingsDetails'));
const Catalogs = React.lazy(() => import('pages/Catalogs'));
const CatalogDetails = React.lazy(() => import('pages/CatalogDetails'));
const Offers = React.lazy(() => import('pages/Offers'));
const OfferDetails = React.lazy(() => import('pages/OfferDetails'));
const Suppliers = React.lazy(() => import('pages/Suppliers'));
const Customers = React.lazy(() => import('pages/Customers'));
const Tenders = React.lazy(() => import('pages/Tenders'));
const CustomerDetailsTenderTemplates = React.lazy(() => import('pages/CustomerDetailsTenderTemplates'));
const CustomerDetailsInfo = React.lazy(() => import('pages/CustomerDetailsInfo'));
const TenderDetails = React.lazy(() => import('pages/TenderDetails'));
const CustomerDetailsOffers = React.lazy(() => import('pages/CustomerDetailsOffers'));
const CustomerDetailsCatalog = React.lazy(() => import('pages/CustomerDetailsCatalog'));
const Orders = React.lazy(() => import('pages/Orders'));
const OrderDetails = React.lazy(() => import('pages/OrderDetails'));
const CustomerDetailsOrders = React.lazy(() => import('pages/CustomerDetailsOrders'));
const CustomerDetailsTenders = React.lazy(() => import('pages/CustomerDetailsTenders'));
const Results = React.lazy(() => import('pages/Results'));
const ResultDetails = React.lazy(() => import('pages/ResultDetails'));
const Confirmations = React.lazy(() => import('pages/Confirmations'));
const ConfirmationDetails = React.lazy(() => import('pages/ConfirmationDetails'));
const SupplierDetailsInfo = React.lazy(() => import('pages/SupplierDetailsInfo'));
const SupplierDetailsOffers = React.lazy(() => import('pages/SupplierDetailsOffers'));
const SupplierDetailsCustomers = React.lazy(() => import('pages/SupplierDetailsCustomers'));
const Users = React.lazy(() => import('pages/Users'));
const UserDetails = React.lazy(() => import('pages/UserDetails'));

const LayoutWithAuth = withAuth(Layout);

const router = createBrowserRouter([
  {
    path: routs.signIn,
    element: (
      <React.Suspense>
        <SignIn />
      </React.Suspense>
    ),
  },
  {
    path: '*',
    element: (
      <NotFoundPage />
    ),
  },
  {
    path: '/',
    element: <LayoutWithAuth />,
    children: [
      {
        path: '/',
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Navigate to="bindings" replace />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: routs.customers,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Customers />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.customers}/:id`,
        element: (
          <CustomerDetailsLayout />
        ),
        children: [
          {
            path: `/${routs.customers}/:id/`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <Navigate to="info" replace />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.customers}/:id/info`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <CustomerDetailsInfo />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.customers}/:id/tender-templates`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <CustomerDetailsTenderTemplates />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.customers}/:id/offers`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <CustomerDetailsOffers />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.customers}/:id/orders`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <CustomerDetailsOrders />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.customers}/:id/catalog`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <CustomerDetailsCatalog />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.customers}/:id/tenders`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <CustomerDetailsTenders />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.customers}/:id/points`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <p>point</p>
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
        ],
      },
      {
        path: routs.catalogs,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Catalogs />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.catalogs}/:id`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <CatalogDetails />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: routs.orders,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Orders />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.orders}/:id`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <OrderDetails />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: routs.tenders,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Tenders />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.tenders}/:id`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <TenderDetails />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: routs.bindings,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Bindings />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: routs.results,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Results />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.results}/:id`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <ResultDetails />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.bindings}/:id`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <BindingsDetails />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: routs.offers,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Offers />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.offers}/:id`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <OfferDetails />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.suppliers}`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Suppliers />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.suppliers}/:id`,
        element: (
          <SupplierDetailsLayout />
        ),
        children: [
          {
            path: `/${routs.suppliers}/:id/`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <Navigate to="info" replace />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.suppliers}/:id/info`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <SupplierDetailsInfo />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.suppliers}/:id/customers`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <SupplierDetailsCustomers />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
          {
            path: `/${routs.suppliers}/:id/offers`,
            element: (
              <ErrorBoundary>
                <React.Suspense>
                  <SupplierDetailsOffers />
                </React.Suspense>
              </ErrorBoundary>
            ),
          },
        ],
      },
      {
        path: `${routs.confirmations}`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Confirmations />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.confirmations}/:id`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <ConfirmationDetails />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.users}`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <Users />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
      {
        path: `${routs.users}/:id`,
        element: (
          <ErrorBoundary>
            <React.Suspense>
              <UserDetails />
            </React.Suspense>
          </ErrorBoundary>
        ),
      },
    ],
  },
]);

export default router;

import React from 'react';
import styles from './nothingFound.module.scss';

export interface INothingFound {
  text: string;
}

export function NothingFound({ text }: INothingFound) {
  return (
    <div className={styles.container}>
      <h2 className={styles.text}>{text}</h2>
    </div>
  );
}

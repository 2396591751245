import { createSlice } from '@reduxjs/toolkit';

interface IHeaderTitleState {
  title: string;
}

const initialState: IHeaderTitleState = {
  title: '',
};

export const headerTitleSlice = createSlice({
  name: 'HeaderTitle',
  initialState,
  reducers: {
    set(state, action) {
      // eslint-disable-next-line no-param-reassign
      state.title = action.payload;
    },
  },
});

export const { set: setHeaderTitle } = headerTitleSlice.actions;

export default headerTitleSlice.reducer;

import { gql } from '@apollo/client';

export const QUERY_ORGANIZATIONS = gql`
  query($typeId: Int, $page: Int) {
    organizations(typeId: $typeId, page: $page) {
      data {
          id
          name
          alias
          taxRegistrationReasonCode
          taxPayerId
          createdAt
          updatedAt
      }
      totalPageCount
      page
    }
  }
`;

export const QUERY_ORGANIZATION_NAME = gql`
  query($id: Int) {
    organization(id: $id) {
      name
      type {
        id
      }
    }
  }
`;

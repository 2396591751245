import React from 'react';
import { Spinner } from 'shared/ui';

import styles from './item.module.scss';

export interface IInfoListItem {
  label: string,
  value: string | number | React.ReactNode,
  loading?: boolean,
};

function InfoListItem({ label, value, loading }: IInfoListItem) {
  return (
    <div className={styles.item}>
      {label && <span className={styles.label}>{label}</span>}
      {loading ? <Spinner type="string" /> : <span className={styles.value}>{value}</span>}
    </div>
  );
};

export { InfoListItem };

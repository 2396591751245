enum ERouts {
  signIn = 'sign-in',
  customers = 'customers',
  catalogs = 'catalogs',
  orders = 'orders',
  tenders = 'tenders',
  bindings = 'bindings',
  offers = 'offers',
  suppliers = 'suppliers',
  results = 'results',
  confirmations = 'confirmations',
  users = 'users',
}

export default ERouts;

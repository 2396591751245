import React from 'react';
import { QUERY_USER, useUserQuery } from 'shared/api/user';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import ERouts from 'shared/routs';
import { setUser } from 'store/slices/user';

export function withAuth(WrappedComponent: any) {
  const WithUser = function (props: any) {
    const user = useAppSelector((state) => state.user);

    if (!user) {
      const dispatch = useAppDispatch();
      const { loading, error, data } = useUserQuery(QUERY_USER);

      if (loading) {
        return <div style={{ height: '100vh', backgroundColor: '#f9f5ee' }}> </div>;
      }

      if (error || !data?.user?.roles?.some((el) => el?.key === 'admin')) {
        window.location.href = (`/${ERouts.signIn}`);

        return <div style={{ height: '100vh', backgroundColor: '#f9f5ee' }}> </div>;
      }

      dispatch(setUser(data?.user));
    }

    return <WrappedComponent {...props} />;
  };

  return WithUser;
};

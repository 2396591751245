/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { MUTATION_REFRESH } from 'shared/api/auth';
import ERouts from 'shared/routs';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_HOST}/graphql`,
});

// access token ----------------------------
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them

  return {
    headers: {
      ...headers,
      'X-Access-Token': token || '',
    },
  };
});
// ---------------------------- access token

// refresh token ----------------------------
const errorLink = onError(({ networkError, operation, forward }: any) => {
  const refreshToken = localStorage.getItem('refresh');

  if (networkError && networkError.statusCode === 401 && refreshToken) {
    return fromPromise(
      refresh(refreshToken),
    )
      .filter((value) => Boolean(value))
      .flatMap((accessToken) => {
        const oldHeaders = operation.getContext().headers;
        // modify the operation context with a new token
        operation.setContext({
          headers: {
            ...oldHeaders,
            'X-Access-Token': accessToken || '',
          },
        });

        // retry the request, returning the new observable
        return forward(operation);
      });
  }

  return;
});
// ---------------------------- refresh token

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  // connectToDevTools: true,
});

let isRefreshing = false; // Флаг для отслеживания состояния обновления токена
let refreshSubscribers: any = []; // Массив для хранения ожидающих запросов

const refresh = (refreshToken: string) => {
  if (!isRefreshing) {
    isRefreshing = true;

    return client.mutate({
      mutation: MUTATION_REFRESH,
      variables: { refreshToken },
      errorPolicy: 'all',
    }).then((refreshResponse: any) => {
      if (refreshResponse?.errors?.length && refreshResponse?.errors?.length > 0) { // если в запросе ошибка, то удаляем токены из localStorage
        localStorage.removeItem('refresh');
        localStorage.removeItem('token');
        throw new Error();
      }

      localStorage.setItem('refresh', refreshResponse?.data?.refresh?.refreshToken || '');
      localStorage.setItem('token', refreshResponse?.data?.refresh?.accessToken || '');

      isRefreshing = false; // Сброс флага
      // Вызываем все ожидающие запросы
      refreshSubscribers.forEach(
        (subscriber: any) => subscriber(refreshResponse?.data?.refresh?.accessToken),
      );
      refreshSubscribers = []; // очищаем массив с запросами

      return refreshResponse?.data?.refresh?.accessToken;
    }).catch((error: any) => {
      window.location.href = `/${ERouts.signIn}`; // в случае ошибки редиректим на страницу логина
      console.log(`refresh error ${error}`);
    });
  }

  return new Promise((resolve) => { // отправляем запрос в массив ожидания
    refreshSubscribers.push((token: any) => {
      resolve(token);
    });
  });
};

export const withApollo = (component: () => React.ReactNode) => function providerFunc() {
  return (
    <ApolloProvider client={client}>
      {component()}
    </ApolloProvider>
  );
};

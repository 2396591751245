import { Spinner } from 'pharm-ui-kit';
import React from 'react';

import { InfoListItem, IInfoListItem } from 'shared/ui';

import styles from './infoBlock.module.scss';

type TProps = {
  title: string,
  items: IInfoListItem[],
  actionComponent?: React.ReactNode | undefined,
  className?: string,
  noItemsMessage?: string | React.ReactNode,
  isLoading?: boolean,
};

const renderItems = (items: IInfoListItem[]) => items.map((item, idx) => (
  <InfoListItem key={`${item.label}-${idx.toString()}`} value={item?.value} label={item?.label} loading={item?.loading} />
));

function InfoBlock({
  title,
  items,
  actionComponent,
  noItemsMessage,
  isLoading,
  className,
}: TProps) {
  return (
    <div className={className || ''}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        {actionComponent}
      </div>
      {isLoading ? <Spinner type="table" /> : <div>{items?.length ? renderItems(items) : noItemsMessage}</div>}
    </div>
  );
};

export { InfoBlock };
export type { IInfoListItem };

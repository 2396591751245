import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'User',
  initialState: null,
  reducers: {
    set(state, action) {
      // eslint-disable-next-line no-param-reassign
      state = action.payload;
    },
  },
});

export const { set: setUser } = userSlice.actions;

export default userSlice.reducer;

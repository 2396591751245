import { gql } from '@apollo/client';

export const MUTATION_REFRESH = gql`
  mutation($refreshToken: String) {
    refresh(input: { refreshToken: $refreshToken }) {
        accessToken
        refreshToken
    }
  }
`;

export const MUTATION_LOGOUT = gql`
  mutation($refreshToken: String) {
    logout(input: { refreshToken: $refreshToken })
  }
`;

import React, { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import styles from './menu-item.module.scss';

type TLabel = {
  children: string;
};

export function Label({ children }: TLabel) {
  return <p className={styles.label}>{children}</p>;
}

type TIcon = {
  children: React.ReactNode;
};

export function Icon({ children }: TIcon) {
  return <div className={styles.icon}>{children}</div>;
}

export interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  link: string;
  isActive: boolean;
  className?: string;
  isCollapsed?: boolean;
  isDisabled?: boolean;
}

export function Container({
  children,
  link,
  className,
  isCollapsed,
  isDisabled,
  isActive,
  ...props
}: IProps) {
  return (
    <Link className={styles.link} to={link} onClick={(e) => isDisabled && e.preventDefault()}>
      <button
        type="button"
        className={`
          ${styles.container}
          ${isCollapsed ? styles['container--collapsed'] : ''}
          ${className || ''}
          ${isActive && styles['container--active']}
        `}
        disabled={isDisabled}
        {...props}
      >
        {children}
      </button>
    </Link>
  );
}

import React, { ComponentType } from 'react';

interface IObject {
  id: number | string;
}

export function renderComponentsList<T extends IObject>(
  data: T[] | null | undefined,
  Component: ComponentType<T>,
) {
  if (!data || !Component) return null;

  return data?.map((el) => (
    <Component key={el?.id} {...el} />
  ));
}

export function removeLocalStoreElements(elements: string[]): boolean {
  if (elements?.length <= 0 || !Array.isArray(elements)) return false;

  elements?.forEach((el) => localStorage.removeItem(el));

  return true;
}

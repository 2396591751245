import React from 'react';
import { HeaderTitle } from 'features/HeaderTitle';

import styles from './header.module.scss';

export default function Header() {
  return (
    <div className={styles.container}>
      <HeaderTitle />
    </div>
  );
};

import * as Apollo from '@apollo/client';
import { TypedDocumentNode } from '@apollo/client';
import { IUser } from 'shared/types';

export type TSignInVariables = {
  login: string,
  password: string,
};

export type TSignInMutation = {
  login: {
    accessToken?: string;
    refreshToken?: string;
    user?: IUser;
  }
};

export const useSignInMutation = (query: TypedDocumentNode) => (
  Apollo.useMutation<TSignInMutation, TSignInVariables>(query, { errorPolicy: 'all' })
);

// ---------------------------------------------------

export type TRefreshVariables = {
  refreshToken: string,
};

export type TRefreshMutation = {
  refresh: {
    accessToken?: string;
    refreshToken?: string;
  }
};

export const useRefreshMutation = (query: TypedDocumentNode) => (
  Apollo.useMutation<TRefreshMutation, TRefreshVariables>(query, { errorPolicy: 'all' })
);

// ---------------------------------------------------

export type TLogoutVariables = {
  refreshToken: string | null,
};

export type TLogout = {
  logout: boolean;
};

export const useLogout = (query: TypedDocumentNode) => (
  Apollo.useMutation<TRefreshMutation, TLogoutVariables>(query, { errorPolicy: 'all' })
);

import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useOrganizationQuery } from 'shared/api/organizations';
import { QUERY_ORGANIZATION_NAME } from 'shared/api/organizations/queries';
import { useHeaderTitle } from 'shared/hooks/headerTitle';
import { TabsDown, TItem } from 'shared/ui';
import { getPartOfUrl } from 'shared/utils/strings';

const menuItems: TItem[] = [
  {
    id: 'info',
    label: 'Информация',
    url: 'info',
  },
  {
    id: 'customers',
    label: 'Заказчики',
    url: 'customers',
  },
  {
    id: 'offers',
    label: 'Прайсы',
    url: 'offers',
  },
];

export default function SupplierDetailsLayout() {
  const location = useLocation();
  const selectedMenuItem = getPartOfUrl(location.pathname, 3);
  const organizationId = Number(getPartOfUrl(location.pathname, 2));

  const navigate = useNavigate();

  const { data: organization } = useOrganizationQuery(
    QUERY_ORGANIZATION_NAME,
    { id: organizationId },
  );
  const organizationName = organization?.organization?.name;
  const organizationTypeId = organization?.organization?.type?.id;
  const { setHeaderTitle: setTitle } = useHeaderTitle();

  useEffect(() => {
    if (Number(organizationTypeId) === 10 || (organization && !organization?.organization)) {
      navigate('/404');
    }

    if (organizationName) setTitle(organizationName);
  }, [organization]);

  return (
    <div>
      <TabsDown items={menuItems} selectedItem={selectedMenuItem} />
      <Outlet />
    </div>
  );
};

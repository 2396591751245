import { gql } from '@apollo/client';

export const QUERY_USER = gql`
  query {
    user {
      roles {
        key
      }
      name
      updatedAt
      id
      organizations {
          name
      }
      createdAt
      login
    }
  }
`;

import * as Apollo from '@apollo/client';
import { TypedDocumentNode } from '@apollo/client';
import { IResponseGraphQl, IOrganization } from 'shared/types';

interface IOrganizationsQueryVars {
  typeId: number,
  page: number,
}

interface IOrganizationsResult {
  organizations: IResponseGraphQl<IOrganization>
};

export const useOrganizationsQuery = (
  query: TypedDocumentNode,
  variables?: IOrganizationsQueryVars,
) => (
  Apollo.useQuery<IOrganizationsResult, IOrganizationsQueryVars>(
    query,
    { notifyOnNetworkStatusChange: true, variables },
  )
);

// ---------------------------------------------------

interface IOrganizationQueryVars {
  id: number,
}

interface IOrganizationQueryResult {
  organization: IOrganization
};

export const useOrganizationQuery = (
  query: TypedDocumentNode,
  variables?: IOrganizationQueryVars,
) => (
  Apollo.useQuery<IOrganizationQueryResult>(
    query,
    { notifyOnNetworkStatusChange: true, variables },
  )
);

/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Icons, Logo } from 'shared/images';
import { SmallScreenIcon, FullScreenIcon } from 'shared/images/icons';
import { ButtonIcon } from 'shared/ui';
import ERouts from 'shared/routs';
import { getFirstPath } from 'shared/utils/strings';
import { removeLocalStoreElements } from 'shared/utils';
import { useLogout, MUTATION_LOGOUT } from 'shared/api/auth';

import { Icon, Container, Label } from './MenuItem';
import styles from './menu.module.scss';

type TMenuItems = {
  name: string;
  label: string;
  icon: React.ReactNode;
  link: ERouts | string;
  isDisabled?: boolean;
}[];

const menuItems: TMenuItems = [
  { name: 'customers', label: 'Заказчики', icon: <Icons.LotIcon />, link: ERouts.customers },
  { name: 'catalogs', label: 'Справочники', icon: <Icons.ListIcon />, link: ERouts.catalogs },
  { name: 'orders', label: 'Заявки', icon: <Icons.SelectedIcon />, link: ERouts.orders },
  { name: 'tenders', label: 'Тендеры', icon: <Icons.StartIcon />, link: ERouts.tenders },
  { name: 'bindings', label: 'Привязки', icon: <Icons.LinkIcon />, link: ERouts.bindings },
  {
    name: 'results',
    label: 'Результаты',
    icon: <Icons.ImportantIcon />,
    link: ERouts.results,
  },
  { name: 'points', label: 'Аптеки', icon: <Icons.MapPinIcon />, link: '/', isDisabled: true },
  {
    name: 'suppliers',
    label: 'Поставщики',
    icon: <Icons.WebsiteIcon />,
    link: ERouts.suppliers,
  },
  { name: 'offers', label: 'Прайсы', icon: <Icons.CurrencyIcon />, link: ERouts.offers },
  {
    name: 'minRequests',
    label: 'Мин. заказы',
    icon: <Icons.List2Icon />,
    link: '/',
    isDisabled: true,
  },
  { name: 'invoices', label: 'Накладные', icon: <Icons.LikeIcon />, link: ERouts.confirmations },
  { name: 'files', label: 'Файлы', icon: <Icons.PrintIcon />, link: '/', isDisabled: true },
  { name: 'users', label: 'Пользователи', icon: <Icons.User />, link: ERouts.users },
];

// ------------------------------

type TMenuItemsProps = {
  items: TMenuItems;
  isCollapsed: boolean;
  activeMenuItem: string;
};

function MenuItems({ items, isCollapsed, activeMenuItem }: TMenuItemsProps) {
  return (
    <>
      {items.map((item, id) => (
        <Container
          key={`${id.toString()} + ${item?.label}`}
          link={item.link}
          isCollapsed={isCollapsed}
          isDisabled={item.isDisabled}
          isActive={activeMenuItem === item.name}
        >
          <Icon>{item.icon}</Icon>
          {!isCollapsed && <Label>{item.label}</Label>}
        </Container>
      ))}
    </>
  );
}

// ---------------------------------

export function Menu() {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [activeMenuItem, setActiveMenuItem] = useState<string>('');
  const [setLogout] = useLogout(MUTATION_LOGOUT);

  const location = useLocation();

  useEffect(() => {
    setActiveMenuItem(getFirstPath(location.pathname));
  }, [location]);

  const handleLogout = () => {
    const refreshToken = localStorage.getItem('refresh');

    setLogout({ variables: { refreshToken } }).then((logoutData) => {
      // @ts-ignore
      if (logoutData?.data?.logout) {
        removeLocalStoreElements(['refresh', 'token', 'currentCompany']);
        window.location.href = `/${ERouts.signIn}`;
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.sider} ${isCollapsed && styles['sider--collapsed']}`}>
        <div className={styles.header}>
          <Logo />
          <ButtonIcon
            icon={isCollapsed ? <SmallScreenIcon /> : <FullScreenIcon />}
            buttonType="default"
            onClick={() => setIsCollapsed((state) => !state)}
          />
        </div>
        <MenuItems items={menuItems} isCollapsed={isCollapsed} activeMenuItem={activeMenuItem} />
      </div>
      <div className={styles.footer}>
        <ButtonIcon icon={<Icons.Logout />} buttonType="default" onClick={handleLogout} />
      </div>
    </div>
  );
}

import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useOrganizationQuery } from 'shared/api/organizations';
import { QUERY_ORGANIZATION_NAME } from 'shared/api/organizations/queries';
import { getPartOfUrl } from 'shared/utils/strings';
import { setHeaderTitle } from 'store/slices/headerTitle';
import { useAppDispatch } from './redux';

function useHeaderTitle(text?: string) {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    if (text) dispatch(setHeaderTitle(text));

    return () => dispatch(setHeaderTitle(''));
  }, []);

  return { setHeaderTitle: (value?: string) => dispatch(setHeaderTitle(value)) };
};

interface ISetCompanyNameToHeader {
  mode: 'searchString' | 'params',
  paramName?: string,
}

function useSetCompanyNameToHeader({ mode, paramName = 'customerId' }: ISetCompanyNameToHeader) {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const customerId = mode === 'searchString' ? Number(getPartOfUrl(location.pathname, 2)) : Number(searchParams.get(paramName));

  const { data: organization } = useOrganizationQuery(
    QUERY_ORGANIZATION_NAME,
    { id: customerId },
  );
  const customerName = organization?.organization?.name;
  const { setHeaderTitle: setTitle } = useHeaderTitle();

  useEffect(() => {
    if (customerName) setTitle(customerName);
  }, [customerName]);
}

function useSetEntityIdToHeader(entity?: string) {
  const location = useLocation();
  const id = Number(getPartOfUrl(location.pathname, 2)) || -1;
  const entityText = entity ? `${entity} - ` : '';

  useHeaderTitle(`${entityText}${id}`);
}

export { useHeaderTitle, useSetCompanyNameToHeader, useSetEntityIdToHeader };

import React from 'react';

import { Button } from 'shared/ui';

import styles from './notFoundPage.module.scss';

export function NotFoundPage() {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>404</h1>
      <p className={styles.description}>Некорректная ссылка. Страница не найдена.</p>
      <a href="/" tabIndex={-1}><Button size="l" label="Вернуться" buttonType="primary" className={styles.button} /></a>
    </div>
  );
}

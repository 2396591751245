import * as Apollo from '@apollo/client';
import { TypedDocumentNode } from '@apollo/client';
import { IResponseGraphQl, IUser } from 'shared/types';

interface IUserQueryVars {
  id?: number;
}
interface IUserResult {
  user: IUser;
};

export const useUserQuery = (
  query: TypedDocumentNode,
  variables?: IUserQueryVars,
) => (
  Apollo.useQuery<IUserResult, IUserQueryVars>(
    query,
    { notifyOnNetworkStatusChange: true, variables },
  )
);

// ---------------------------------------------------

interface IUsersQueryVars {
  name?: string;
  login?: string;
  page?: number;
  sort?: string;
}

interface IUsersResult {
  users: IResponseGraphQl<IUser>
};

export const useUsersQuery = (
  query: TypedDocumentNode,
  variables?: IUsersQueryVars,
) => (
  Apollo.useQuery<IUsersResult, IUsersQueryVars>(
    query,
    { notifyOnNetworkStatusChange: true, variables },
  )
);

// ---------------------------------------------------

export type TCreateUserVariables = {
  name?: IUser['name'],
  login?: IUser['login'],
  password: string,
};

export type TCreateUserMutation = {
  createUser: IUser,
};

export const useCreateUserMutation = (query: TypedDocumentNode) => (
  Apollo.useMutation<TCreateUserMutation, TCreateUserVariables>(query)
);

// ---------------------------------------------------
